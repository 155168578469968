import React from 'react';

import { Icon, Button, Input } from 'antd';

const GenericSearchProps = (options = {}) => {
  const { dataIndex, callbackRef, searchInputRef, onFilter } = options;

  const defaultOnFilter = (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase());

  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={callbackRef}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button type='primary' onClick={confirm} icon='search' size='small' style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={clearFilters} size='small' style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type='search' style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: onFilter || defaultOnFilter,
    onFilterDropdownVisibleChange: visible => {
      if (visible && searchInputRef) {
        setTimeout(() => searchInputRef.select());
      }
    }
  };
};

export default GenericSearchProps;
