import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'antd';

import { useFB } from '../hooks/useFB';

const StyledButton = styled(Button)`
  width: 250px;
  height: 35px;
  border-radius: 4px;
  background: #3b5998;
  color: white;
  border: 0px transparent;
  text-align: center;
  margin: 5px;
  display: inline-block;

  &:hover {
    background: #3b5998;
    opacity: 0.6;
  }
`;

const FacebookLoginButton = () => {
  const { t: translate } = useTranslation();
  const { login } = useFB();

  return (
    <StyledButton type='primary' icon='facebook' size='large' onClick={login}>
      {translate('button.login')}
    </StyledButton>
  );
};

export default FacebookLoginButton;
