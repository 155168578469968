import React from 'react';
import {useTranslation} from 'react-i18next';

import {Descriptions} from 'antd';

import {useDataStore} from '../hooks/useDataStore';

const Summary = props => {
    const {shareCount, likeCount, pageId, postId, dataCollectedAt, rawComments} = useDataStore();
    const {t: translate, i18n} = useTranslation();

    return (
        <Descriptions bordered>
            <Descriptions.Item label={translate('summary_table.page_id')} span={2}>
                {pageId}
            </Descriptions.Item>
            <Descriptions.Item label={translate('summary_table.post_id')} span={2}>
                {postId}
            </Descriptions.Item>
            <Descriptions.Item label={translate('summary_table.shares')}>{shareCount}</Descriptions.Item>
            <Descriptions.Item label={translate('summary_table.likes')}>{likeCount}</Descriptions.Item>
            <Descriptions.Item
                label={translate('summary_table.comments')}>{rawComments.length || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label={translate('summary_table.data_collected_at')}>
                {dataCollectedAt ? new Date(dataCollectedAt).toLocaleString(i18n.language === 'vi' ? 'vi-VN' : 'en-US') : 'N/A'}
            </Descriptions.Item>
        </Descriptions>
    );
};

export default Summary;