import {isWithinInterval} from 'date-fns'

export const WINNING_CONDITIONS = {
    TAGS: 'tags',
    HASHTAGS: 'hashtags',
    ANSWER: 'answer',
    TIME_RANGE: 'time_range'
};

export const verifyAnswer = (correctAnswers, input) => {
    const {userAnswer} = input;

    return correctAnswers.some(correctAnswer => new RegExp(`\\b${correctAnswer}\\b`).test(userAnswer));
};

export const verifyNumberOfUserTags = (numberOfUserTags, input) => {
    const {usertags} = input;

    return usertags.length >= numberOfUserTags;
};

export const verifyHashtags = (expectedHashtags, input) => {
    const {hashtags} = input;

    return expectedHashtags.every(expectedTag => hashtags.find(actualTag => actualTag.name === expectedTag));
};

export const verifyCommentTime = (range, input) => {
    const [start, end] = range
    const {created_time} = input

    if (start && end && created_time) {
        return isWithinInterval(new Date(created_time), {start: new Date(start), end: new Date(end)})
    }

    return false
}