import {hot} from 'react-hot-loader/root';
import React, {useEffect, useState} from 'react';
import {Button, Divider, Layout, Radio, Typography} from 'antd';
import {useTranslation} from 'react-i18next';

import {useFB} from './hooks/useFB';
import {useDataStore} from './hooks/useDataStore';

import {augmentCommentListWithMetadata, findWinners} from './lib';

import FacebookLoginButton from './components/FacebookLoginButton';
import Summary from './components/Summary';
import CommentList from './components/CommentList';
import ExportButton from './components/ExportButton';
import Footer from './components/Footer';
import Wizard from './components/Wizard';

import Loading from './Loading';

const App = () => {
    const {
        possibleAnswers,
        number,
        minTags,
        hashtags,
        timeRange,

        rawComments,
        commenters,
        processedComments, setProcessedComments,

        winningConditions,
        isFetching,
    } = useDataStore();

    const {t: translate, ready: translationReady, i18n} = useTranslation();
    const {FB, loggedIn, login} = useFB();

    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (!isFetching) {
            setProcessing(true)
            const augmented = augmentCommentListWithMetadata(rawComments, commenters);

            const result = findWinners(winningConditions, {
                comments: augmented,
                numberOfTags: minTags,
                hashtags,
                possibleAnswers,
                randomNumber: number,
                timeRange
            });

            setProcessedComments(result);
            setProcessing(false)
        }
    }, [
        isFetching,

        rawComments,
        commenters,

        setProcessedComments,

        possibleAnswers,
        number,
        minTags,
        hashtags,
        timeRange,

        winningConditions,
    ]);

    if (!FB || !translationReady) {
        return <Loading/>;
    }

    if (!loggedIn) {
        return (
            <div
                style={{
                    display: 'flex',
                    width: '50vw',
                    height: '50vh',
                    margin: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <FacebookLoginButton/>
            </div>
        );
    }

    return (
        <>
            <Layout>
                <Layout.Header>
                    <Button type='primary' onClick={login}>
                        {translate('button.switch_account')}
                    </Button>
                    <Radio.Group
                        style={{paddingLeft: 50}}
                        defaultValue={i18n.language}
                        onChange={e => i18n.changeLanguage(e.target.value)}
                    >
                        <Radio.Button value='en'>English</Radio.Button>
                        <Radio.Button value='vi'>Tiếng Việt</Radio.Button>
                    </Radio.Group>
                </Layout.Header>
                <Layout.Content>
                    <Wizard/>
                    <Divider orientation="left">
                        <Typography.Title level={4}>
                            {translate('summary')}
                        </Typography.Title>
                    </Divider>
                    <Summary/>
                    <Divider orientation="left">
                        <Typography.Title level={4}>
                            {translate('comments')}
                        </Typography.Title>
                    </Divider>
                    <CommentList
                        loading={isFetching || processing}
                        dataSource={processedComments}
                        buttons={<ExportButton data={processedComments}/>}
                    />
                </Layout.Content>
                <Footer/>
            </Layout>
        </>
    );
};

export default hot(App);
