import React from 'react'

import en from 'antd/es/date-picker/locale/en_US';
import vi from 'antd/es/date-picker/locale/vi_VN';

import {Button, Checkbox, Col, DatePicker, Icon, Input, InputNumber, Popover, Row, Select, Typography} from 'antd';
import {Trans, useTranslation} from 'react-i18next';

import {useDataStore} from '../hooks/useDataStore';
import {rand} from "../lib";
import {WINNING_CONDITIONS} from "../verifyConditions";

const CommentFilter = () => {
    const {t: translate, i18n} = useTranslation()

    const {
        min, setMin,
        max, setMax,
        number, setNumber,
        minTags, setMinTags,
        setWinningConditions,
        setTimeRange,
        setPossibleAnswers,
        setHashtags
    } = useDataStore()

    return (
        <>
            <Row type="flex" align="middle" justify="center">
                <Col span={3}>
                    <Typography style={{textAlign: 'center'}}>{translate('random_number')}</Typography>
                </Col>
                <Col span={12}>
                    <Input.Group compact>
                        <Select defaultValue='1'>
                            <Select.Option value='1'>{translate('random_number_range.between')}</Select.Option>
                        </Select>
                        <InputNumber
                            style={{width: 80, borderRight: 0}}
                            placeholder='Minimum'
                            value={min}
                            onChange={value => setMin(value)}
                        />
                        <Input
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                                backgroundColor: '#fff'
                            }}
                            placeholder='~'
                            disabled
                        />
                        <InputNumber
                            style={{width: 80, borderLeft: 0}}
                            placeholder='Maximum'
                            value={max}
                            onChange={value => setMax(value)}
                        />
                        <InputNumber style={{width: 80}} value={number} onChange={value => setNumber(value)}/>
                        <Button type='primary' icon='sync' onClick={() => setNumber(rand(min, max))}/>
                    </Input.Group>
                </Col>
            </Row>
            <Row type="flex" align="middle" justify="center">
                <Col span={3}>
                    <Typography style={{textAlign: 'center'}}>{translate('winning_conditions')}</Typography>
                </Col>
                <Col span={12}>
                    <Checkbox.Group
                        onChange={checkedValues => {
                            setWinningConditions(checkedValues);
                        }}
                    >
                        <Row type="flex" align="middle" gutter={[8, 16]}>
                            <Col span={8}>
                                <Checkbox value={WINNING_CONDITIONS.ANSWER}>{translate('answer')}</Checkbox>
                                <Popover content={<Trans i18nKey='guide_answer'/>}>
                                    <Icon style={{paddingRight: 10}} type='question-circle'/>
                                </Popover>
                            </Col>
                            <Col span={5}>
                                <Select
                                    mode='tags'
                                    style={{width: '50vw'}}
                                    onChange={value => {
                                        // array of tags
                                        setPossibleAnswers(value);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row type="flex" align="middle" gutter={[8, 16]}>
                            <Col span={8}>
                                <Checkbox value={WINNING_CONDITIONS.TAGS}>{translate('number_of_tags')}</Checkbox>
                                <Popover content={translate('guide_tags')}>
                                    <Icon style={{paddingRight: 10}} type='question-circle'/>
                                </Popover>
                            </Col>
                            <Col span={5}>
                                <InputNumber value={minTags}
                                             onChange={value => setMinTags(value)}/>
                            </Col>
                        </Row>

                        <Row type="flex" align="middle" gutter={[8, 16]}>
                            <Col span={8}>
                                <Checkbox value={WINNING_CONDITIONS.HASHTAGS}>{translate('hashtags')}</Checkbox>
                                <Popover content={<Trans i18nKey='guide_hashtags'/>}>
                                    <Icon style={{paddingRight: 10}} type='question-circle'/>
                                </Popover>
                            </Col>
                            <Col span={5}>
                                <Select
                                    mode='tags'
                                    style={{width: '50vw'}}
                                    placeholder='#hashtags'
                                    onChange={value => {
                                        // array of tags
                                        setHashtags(value);
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row type="flex" align="middle" gutter={[8, 16]}>
                            <Col span={8}>
                                <Checkbox
                                    value={WINNING_CONDITIONS.TIME_RANGE}>{translate('comment_time_range')}</Checkbox>
                            </Col>
                            <Col span={5}>
                                <DatePicker.RangePicker locale={i18n.language === 'en' ? en : vi}
                                                        showTime={{format: 'HH:mm'}} onChange={(_, dateStrings) => {
                                    setTimeRange(dateStrings)
                                }}/>
                            </Col>
                        </Row>

                    </Checkbox.Group>
                </Col>
            </Row>
        </>
    )
}

export default CommentFilter