import React from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { Parser } from 'json2csv';

import { Button } from 'antd';

const ExportButton = props => {
  const { data, pageId, postId } = props;

  const { t: translate } = useTranslation();

  return (
    <Button
      type='primary'
      icon='download'
      onClick={() => {
        const json2csvParser = new Parser({
          fields: [
            {
              label: 'from',
              value: (row, field) => {
                return `=hyperlink("https://www.facebook.com/profile.php?id=${row[field.label]['id']}")`;
              },
              stringify: false
            },
            'created_time',
            'message',
            {
              label: 'permalink_url',
              value: (row, field) => {
                return `=hyperlink("${row[field.label]}")`;
              },
              stringify: false
            }
          ],
          withBOM: true,
          doubleQuote: '"',
          excelStrings: false
        });
        const csv = json2csvParser.parse(data);
        const blob = new Blob([csv], {
          type: 'text/plain;charset=utf-8'
        });
        saveAs(blob, `total_comments_${pageId}_${postId}.csv`);
      }}
    >
      {translate('button.export')}
    </Button>
  );
};

export default ExportButton;
