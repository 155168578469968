import React, { useState, useEffect, useContext } from 'react';

export const FBContext = React.createContext();
export const useFB = () => useContext(FBContext);

export const FBProvider = ({ children }) => {
  const [FB, setFB] = useState(window.FB);
  const [loggedIn, setLoggedIn] = useState(false);
  const [accessiblePages, setAccessiblePages] = useState([]);

  useEffect(() => {
    window.FB.getLoginStatus(response => {
      setFB(window.FB);

      if (response.status === 'connected') {
        window.FB.api('/me', 'GET', { fields: 'accounts{access_token,id,link,name}' }, response => {
          setAccessiblePages(response.accounts.data);
          setLoggedIn(true);
        });
      }
    });
  }, []);

  return (
    <FBContext.Provider
      value={{
        FB,
        loggedIn,
        getPageId: nameOrId => {
          const found = accessiblePages.find(
            page => page.id === nameOrId || page.name === nameOrId || page.link.includes(nameOrId)
          );

          return found ? found.id : null;
        },
        getTokenForPage: nameOrId => {
          const found = accessiblePages.find(page => page.name === nameOrId || page.id === nameOrId);

          return found ? found.access_token : null;
        },
        login: () => {
          FB.login(
            response => {
              if (response.status === 'connected') {
                FB.api('/me', 'GET', { fields: 'accounts{access_token,id,link,name}' }, response => {
                  setAccessiblePages(response.accounts.data);
                  setLoggedIn(true);
                });
              }
            },
            {
              scope: 'public_profile,email,manage_pages'
            }
          );
        }
      }}
    >
      {children}
    </FBContext.Provider>
  );
};
