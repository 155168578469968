import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Alert, Button, Form, Icon, Input, Popover} from 'antd';

import {useDataStore} from '../hooks/useDataStore';
import {useFB} from '../hooks/useFB';

import image from '../assets/how_to_get_link.png';

const API = 'https://facescraper-ltvclrd2dq-an.a.run.app';

const Wizard = () => {
    const {
        pageId,
        setPageId,
        postId,
        setPostId,

        setRawComments,
        setCommenters,

        setShareCount,
        setLikeCount,
        setDataCollectedAt,

        isFetching,
        setIsFetching,

        postLink,
        setPostLink,
    } = useDataStore();

    const {FB, getPageId, getTokenForPage} = useFB();
    const {t: translate} = useTranslation();

    const [error, setError] = useState(false)

    const collectComments = (total, after) => {
        FB.api(
            `/${pageId}_${postId}`,
            'GET',
            {
                access_token: getTokenForPage(pageId),
                fields:
                    'comments.limit(1000){created_time,from,message,message_tags,permalink_url,attachment,like_count},shares,likes.summary(true)',
                after
            },
            response => {
                if (!response.comments) {
                    setIsFetching(false);
                    return setError(true);
                }

                setError(false);

                const {
                    shares = {},
                    likes = {},
                    comments: {
                        data: comments,
                        paging: {
                            cursors: {after, next}
                        }
                    }
                } = response;

                const {count = 0} = shares;
                const {
                    summary: {total_count = 0}
                } = likes;

                total.push(...comments);

                if (!next) {
                    setRawComments(total);
                    setShareCount(count);
                    setLikeCount(total_count);
                    setDataCollectedAt(Date.now());
                    setIsFetching(false);

                    return;
                }

                return collectComments(total, after);
            }
        );
    };

    const collectUserData = (e) => {
        e.preventDefault()
        setIsFetching(true);

        return fetch(`${API}?url=${encodeURIComponent(postLink)}`)
            .then(async response => {
                if (response.status !== 200) {
                    setIsFetching(false);
                }

                const {data} = await response.json();

                return setCommenters(data);
            })
            .finally(() => {
                setIsFetching(false);
            });
    };

    const handlePostLink = e => {
        const uri = e.target.value;
        setPostLink(uri);

        // https://www.facebook.com/permalink.php?story_fbid=109236827206797&id=109236187206861&__xts__[0]=68.ARANHrErWgNfKytrfigyHFxGzOfpQ2brKmNY72YtFXJZQ68bPYdy1V1oA2dknbPfd6ZXdgM3tYGucsY4WhyLZW1iiU-jcNshecL5LR1se5ZQMT9YmviYwRF5eFwjKzImyhgKFeNBBXUHjk-VvrOm_uFHkvLxYDnM4JOAwL9wfwQLjHNWU1B9dWoTLeDSx4f7sm_aFRMjutQ5q7XcqyHiftR8SJerRoxD5GUp_y37W35Y9S7PV17iEBy1jCmiOJilAKPOJeLpWQg5-feazL8myDMdC0DJqQTuorfX0Sxgrv3ljIloShvLtGVDuFHUkxiaI8JMolfPdG4PKh0xkto&__tn__=-R
        if (uri.includes('/permalink.php?')) {
            const query = uri.split('?').pop();

            if (query) {
                const searchParams = new URLSearchParams(`?${query}`);

                setPageId(searchParams.get('id'));
                setPostId(searchParams.get('story_fbid'));
            }
        }

        // https://www.facebook.com/213601155729878/posts/669934053429917/
        // https://www.facebook.com/toyotavnsports/videos/2391237544492248
        if (uri.includes('/posts/') || uri.includes('/videos/')) {
            const [pageName, postId] = uri
                .replace('/posts', '')
                .replace('/videos', '')
                .split('/')
                .slice(-2);

            setPageId(getPageId(pageName));
            setPostId(postId);
        }
    };

    return (
        <>
            <Form
                style={{margin: 20}}
                layout='horizontal'
                labelCol={{
                    xs: {span: 24},
                    sm: {span: 6}
                }}
                wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 12}
                }}
            >
                <Form.Item label={translate('post_url')}>
                    <Input
                        placeholder='https://www.facebook.com/permalink.php?...'
                        onChange={handlePostLink}
                        suffix={
                            <Popover content={<img alt='how_to_get_link' src={image}/>}
                                     title={translate('how_to_get_link')}>
                                <Icon type='question-circle'/>
                            </Popover>
                        }
                    />
                    {error &&
                    <Alert message='Error' description={translate('error.message')} type='error' showIcon closable/>}
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        xs: {span: 24, offset: 0},
                        sm: {span: 18, offset: 8}
                    }}
                >
                    <Button.Group>
                        <Button type='primary' loading={isFetching} onClick={(e) => {
                            e.preventDefault()
                            setIsFetching(true)

                            return collectComments([])
                        }}>
                            <Icon type="snippets"/>
                            {translate('button.collect_comments')}
                        </Button>
                        <Button type='secondary' loading={isFetching} onClick={collectUserData}>
                            {translate('button.collect_userdata')}
                            <Icon type="usergroup-delete"/>
                        </Button>
                    </Button.Group>
                </Form.Item>
            </Form>
        </>
    );
};

export default Wizard;
