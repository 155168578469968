import React, {useState, useContext} from 'react';
import {rand} from '../lib';

export const Store = React.createContext();
export const useDataStore = () => useContext(Store);

export const DataStoreProvider = ({children}) => {
    const [postId, setPostId] = useState('N/A');
    const [pageId, setPageId] = useState('N/A');

    const [postLink, setPostLink] = useState();

    const [min, setMin] = useState(0);
    const [max, setMax] = useState(999);
    const [number, setNumber] = useState(rand(min, max));
    const [minTags, setMinTags] = useState(0);
    const [hashtags, setHashtags] = useState([]);
    const [possibleAnswers, setPossibleAnswers] = useState([]);
    const [timeRange, setTimeRange] = useState([])

    const [dataCollectedAt, setDataCollectedAt] = useState();

    const [likeCount, setLikeCount] = useState('N/A');
    const [shareCount, setShareCount] = useState('N/A');

    const [rawComments, setRawComments] = useState([]);
    const [commenters, setCommenters] = useState([]);
    const [processedComments, setProcessedComments] = useState([]);

    const [winningConditions, setWinningConditions] = useState([]);

    const [isFetching, setIsFetching] = useState(false)

    return (
        <Store.Provider
            value={{
                winningConditions,
                setWinningConditions,

                rawComments,
                setRawComments,

                commenters,
                setCommenters,

                processedComments,
                setProcessedComments,

                likeCount,
                setLikeCount,
                shareCount,
                setShareCount,

                postLink,
                setPostLink,

                postId,
                setPostId,

                pageId,
                setPageId,

                possibleAnswers, setPossibleAnswers,
                min, setMin,
                max, setMax,
                number, setNumber,
                minTags, setMinTags,
                hashtags, setHashtags,
                timeRange, setTimeRange,

                dataCollectedAt, setDataCollectedAt,

                isFetching, setIsFetching
            }}
        >
            {children}
        </Store.Provider>
    );
};
