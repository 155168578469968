import {
    WINNING_CONDITIONS,
    verifyAnswer,
    verifyHashtags,
    verifyNumberOfUserTags,
    verifyCommentTime
} from './verifyConditions';

export const findWinners = (winningConditions, input) => {
    const {comments, possibleAnswers, numberOfTags, hashtags: expectedHashtags, randomNumber, timeRange} = input;

    return comments
        .map(comment => parseComment(comment, possibleAnswers, randomNumber))
        .map(comment => {
            const eligible = winningConditions.every(condition => {
                switch (condition) {
                    case WINNING_CONDITIONS.ANSWER: {
                        return verifyAnswer(possibleAnswers, comment);
                    }
                    case WINNING_CONDITIONS.HASHTAGS: {
                        return verifyHashtags(expectedHashtags, comment);
                    }
                    case WINNING_CONDITIONS.TAGS: {
                        return verifyNumberOfUserTags(numberOfTags, comment);
                    }
                    case WINNING_CONDITIONS.TIME_RANGE: {
                        return verifyCommentTime(timeRange, comment)
                    }
                    default: {
                        return true;
                    }
                }
            });

            return {
                ...comment,
                eligible
            };
        });
};

export const parseComment = (comment, possibleAnswers, randomNumber) => {
    // comment may not have tags, need default value
    const {message, message_tags = []} = comment;

    const strippedTags = message_tags.reduce((msg, tag) => {
        return msg.replace(tag.name, '').trim();
    }, message);

    const numberChosenByUser = Array.from(strippedTags.matchAll(/[0-9]+/g))
        .reduce((flattened, match) => {
            return flattened.concat(match);
        }, [])
        .find(str => !possibleAnswers.includes(str));

    const userAnswer = strippedTags.replace(`${numberChosenByUser}`, '').trim();

    // hashtags are also counted as tags
    const hashtags = message_tags.filter(tag => `${tag.name}`.startsWith('#'));
    // usertags have type === 'user' explicitly
    const usertags = message_tags.filter(tag => tag.type === 'user');

    const delta = Math.abs(parseInt(randomNumber, 10) - parseInt(numberChosenByUser, 10));

    return {
        ...comment,
        number: parseInt(numberChosenByUser, 10),
        delta: Number.isInteger(delta) ? delta : Number.NEGATIVE_INFINITY,
        usertags,
        hashtags,
        userAnswer
    };
};

export const rand = (first, second) => {
    const min = Math.ceil(first);
    const max = Math.floor(second);

    return Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
};

export const augmentCommentListWithMetadata = (commentList, metaList) => {
    return commentList.flatMap(comment => {
        const {id} = comment;
        const matched = metaList.find(c => c.id === id) || {};

        return {
            ...comment,
            ...matched
        };
    });
};
