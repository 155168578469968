import React from 'react';
import { Spin } from 'antd';

const Loading = () => (
  <div
    style={{
      display: 'flex',
      width: '50vw',
      height: '50vh',
      margin: 'auto',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Spin size='large' />
  </div>
);

export default Loading;
