import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Card, Col, Row, Switch, Table, Tag, Typography} from 'antd';

import GenericSearchProps from './GenericSearchProps';
import CommentFilter from './CommentFilter'

const CommentList = props => {
    const {loading, dataSource, buttons} = props;

    const showAll = c => c;
    const showEligible = c => c.eligible;

    // this need to be a function that returns a filter function
    const [commentFilter, setCommentFilter] = useState(() => showAll);

    const {t: translate, i18n} = useTranslation();
    const [searchInputRef, setSearchInputRef] = useState();

    const defaultSearchPropsOptions = {
        searchInputRef,
        callbackRef: node => {
            setSearchInputRef(node);
        }
    };

    return (
        <>
            <Row>
                <CommentFilter/>
            </Row>
            <Row>
                <Table
                    pagination={{
                        showSizeChanger: true
                    }}
                    rowKey={record => `comment-${record.id}`}
                    title={() => (
                        <>
                            <Row type='flex'>
                                <Col span={20}>
                                    <Typography.Text
                                        style={{padding: 20}}>{translate('button.show_eligible')}</Typography.Text>
                                    <Switch
                                        onChange={checked => {
                                            setCommentFilter(() => (checked ? showEligible : showAll));
                                        }}
                                    />
                                </Col>
                                <Col span={4}>{buttons}</Col>
                            </Row>
                            <Row>

                            </Row>
                        </>
                    )}
                    loading={loading}
                    dataSource={dataSource.filter(commentFilter)}
                    columns={[
                        {
                            title: translate('comments_table.username'),
                            dataIndex: 'from',
                            render: from => {
                                if (!from) {
                                    return <Typography.Text>N/A</Typography.Text>;
                                }

                                const {id, name} = from;

                                return (
                                    <Typography.Text>
                                        <a href={`https://www.facebook.com/profile.php?id=${id}`} target='_blank'
                                           rel='noopener noreferrer'>
                                            {name}
                                        </a>
                                    </Typography.Text>
                                );
                            },
                            ...GenericSearchProps({
                                ...defaultSearchPropsOptions,
                                dataIndex: 'from',
                                onFilter: (value, record) => {
                                    if (record.from && record.from.name) {
                                        return record.from.name
                                            .toString()
                                            .toLowerCase()
                                            .includes(value.toLowerCase());
                                    }

                                    return false;
                                }
                            })
                        },
                        {
                            title: translate('comments_table.id'),
                            dataIndex: 'permalink_url',
                            render: permalink_url => {
                                return (
                                    <Typography.Text>
                                        <a href={permalink_url} target='_blank' rel='noopener noreferrer'>
                                            {new URL(permalink_url).searchParams.get('comment_id')}
                                        </a>
                                    </Typography.Text>
                                );
                            }
                        },
                        {
                            title: translate('comments_table.comment'),
                            dataIndex: 'message',
                            render: message => {
                                return <Typography.Text>{message}</Typography.Text>;
                            },
                            ...GenericSearchProps({
                                ...defaultSearchPropsOptions,
                                dataIndex: 'message'
                            })
                        },
                        {
                            title: translate('comments_table.random_number'),
                            children: [
                                {
                                    title: translate('comments_table.user_number'),
                                    dataIndex: 'number',
                                    render: number => {
                                        return <Typography.Text>{Number.isInteger(number) ? number : 'N/A'}</Typography.Text>;
                                    }
                                },
                                {
                                    title: translate('comments_table.delta'),
                                    dataIndex: 'delta',
                                    render: delta => {
                                        return <Typography.Text>{Number.isInteger(delta) ? delta : 'N/A'}</Typography.Text>;
                                    },
                                    sorter: (current, next) => {
                                        const currentDelta = current.delta;
                                        const nextDelta = next.delta;

                                        // infinity to bottom
                                        if (currentDelta === Number.NEGATIVE_INFINITY) {
                                            return 1;
                                        }

                                        // infinity to bottom
                                        if (nextDelta === Number.NEGATIVE_INFINITY) {
                                            return -1;
                                        }

                                        // whoever has lower diff comes first
                                        if (currentDelta < nextDelta) {
                                            if (Number.NEGATIVE_INFINITY === currentDelta) {
                                                return 1;
                                            }

                                            return -1;
                                        }

                                        if (currentDelta === nextDelta) {
                                            // whoever commented sooner comes first
                                            return new Date(current.created_time) - new Date(next.created_time);
                                        }

                                        return 1;
                                    },
                                    sortDirections: ['ascend']
                                }
                            ]
                        },
                        {
                            title: translate('comments_table.usertags'),
                            dataIndex: 'usertags',
                            render: (tags, record) => {
                                return (
                                    <span>
                  {tags.map((tag, index) => (
                      <Tag color='green' key={`${record.id}-usertag-${index}`}>
                          <Typography.Text>{tag.name}</Typography.Text>
                      </Tag>
                  ))}
                </span>
                                );
                            }
                        },
                        {
                            title: translate('comments_table.hashtags'),
                            dataIndex: 'hashtags',
                            render: (tags, record) => {
                                return (
                                    <span>
                  {tags.map((tag, index) => (
                      <Tag color='blue' key={`${record.id}-hashtag-${index}`}>
                          <Typography.Text>{tag.name}</Typography.Text>
                      </Tag>
                  ))}
                </span>
                                );
                            }
                        },
                        {
                            title: translate('comments_table.like_count'),
                            dataIndex: 'like_count',
                            render: likeCount => {
                                return <Typography.Text>{likeCount}</Typography.Text>;
                            },
                            sorter: (current, next) => {
                                return current.like_count - next.like_count;
                            },
                            sortDirections: ['descend']
                        },
                        {
                            title: translate('comments_table.attachment'),
                            dataIndex: 'attachment',
                            render: attachment => {
                                if (attachment && attachment.media && attachment.media.image && attachment.media.image.src) {
                                    return (
                                        <Card
                                            hoverable
                                            style={{width: 100, height: 100}}
                                            cover={<img alt='attachment' src={attachment.media.image.src}/>}
                                        />
                                    );
                                }

                                return <Typography.Text>N/A</Typography.Text>;
                            }
                        },
                        {
                            title: translate('comments_table.commented_at'),
                            dataIndex: 'created_time',
                            render: created_time => {
                                return <Typography.Text>{new Date(created_time).toLocaleString(i18n.language === 'vi' ? 'vi-VN' : 'en-US')}</Typography.Text>;
                            }
                        }
                    ]}
                />
            </Row>
        </>
    );
};

export default CommentList;
